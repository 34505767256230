<template>
    <div class="home">
      <Header/>
      <div class="content"> 
        <div class="title">{{$t("m.wdqy")}}</div>
        <van-tabs v-model="current" swipeable class="tab" background="transparent" color="#35dcf7" 
        line-height="2px"
        line-width="20px"
        @change="changer"
        title-active-color="#ffffff">
    <van-tab :title="$t('m.wdqydd')" class="item">
    </van-tab>
    <van-tab :title="$t('m.ywcdqydd')" class="item">
    </van-tab>
    </van-tabs>
    <div class="list" v-if="current==0">
      <div  v-for="(item,i) in list" :key="i">
      <div class="card"  v-if="item.status==1" >
        <div class="top">
          <div class="time">{{formatDate(item.createdAt)}}</div>
           <div class="status" v-if="item.time2<0">{{$t('m.wx')}}</div>
          <div class="status"  v-else>{{item.status==1?item.time<=0?$t('m.dzf'):item.time2<0?$t('m.dyz'):$t('m.yyz'):item.status==2?$t('m.ywc'):item.status==3?$t('m.ysh'):''}}</div>
      
        </div>
        <div class="cont">
          <div class="linex">
            <div class="txt">{{$t('m.qyje')}}</div>
            <div class="num">{{(item.amount)}} <span class="dw">USDT</span></div>
          </div>
          <!-- <div class="linex">
            <div class="txt">质押数量</div>
            <div class="num">{{(item.completeAmount||0)}}
               <span class="dw">{{item.completeToken==2?'ARC':item.completeToken==1?'USDT':''}}</span></div>
          </div> -->
          <div class="box" v-if="item.time>0">
              <div class="stixl">{{$t('m.yydjs')}}</div>
              <div class="snum"><van-count-down :time="item.time" @finish="item.time=0" :format="dqyy=='zh-CN'?'DD 天 HH 时 mm 分 ss 秒':'DD Day HH h mm m ss s'"/></div>
          </div>
           <div class="box" v-else-if="item.time2>0">
              <div class="stixl">{{$t('m.zfdjs')}}</div>
              <div class="snum"><van-count-down :time="item.time2" @finish="item.time2=0" :format="dqyy=='zh-CN'?'DD 天 HH 时 mm 分 ss 秒':'DD Day HH h mm m ss s'"/></div>
          </div>          
        </div>
          <div class="buttom">
        <van-button
          :loading="loading"
          type="primary"
          class="btn act"
          @click="toShow(item)"
          v-if="item.status==1&&item.time2>0&&item.time<=0"
        >
        {{$t('m.wcqydd')}} </van-button
        >       
      </div>  
      </div>
      </div>
    </div>
    <div class="list" v-if="current==1">
      <div  v-for="(item,i) in list" :key="i">
      <div class="card"  v-if="item.status!=1" >
        <div class="top">
          <div class="time">{{formatDate(item.createdAt)}}</div>
          <div class="status"  >{{item.status==2?$t('m.ywc'):item.status==3?$t('m.ysh'):''}}</div>
      </div>
        <div class="cont">
          <div class="linex">
            <div class="txt">{{$t('m.qyje')}}</div>
            <div class="num">{{(item.amount)}} <span class="dw">USDT</span></div>
          </div>
          <div class="linex">
            <div class="txt">{{$t('m.zysl')}}</div>
            <div class="num">{{(item.completeAmount*1).toFixed(4)}}
            <span class="dw">{{item.completeToken==2?'ARC':item.completeToken==1?'USDT':''}}</span></div>
          </div>
         
           <div class="box" v-if="item.time3>0">
              <div class="stixl">{{$t('m.jydjs')}}</div>
              <div class="snum"><van-count-down :time="item.time3" @finish="item.time3=0" :format="dqyy=='zh-CN'?'DD 天 HH 时 mm 分 ss 秒':'DD Day HH h mm m ss s'"/></div>
          </div>
        </div>
          <div class="buttom">        
         <van-button
          :loading="loading"
          type="primary"
          class="btn act"
          @click="toOutBack(item)"
          v-if="item.status==2 && item.time3<0"
        >{{$t('m.shqydd')}}
          </van-button
        >
      </div>  
      </div>
      </div>
    </div>
    </div>

    <van-popup v-model="show" round :close-on-click-overlay="false" >
      <div class="popup ">
        <div class="top"> <div class="title">{{$t('m.wcqydd')}}</div>
        <img src="../assets/img/gbs.png" alt="" class="close" @click="show=false"/>
    </div>
       <div class="tips">
        {{$t('m.wcqyddxyzf')}}       
       </div>     
        <div class="line"></div>
       <div class="list">
            <div class="sitem" :class="xz.id==item.id?'act':''" v-for="(item,i) in slist" :key="i" @click="toxz(item)">
                <div class="icon">
                    <img :src="item.img" alt="" class="ico">
                </div>
                <div class="cont">
                    <div class="name">{{item.name}}</div>
                </div>
                <div class="num">{{item.num}}</div>
            </div>
       </div>
         <div class="buttom">
        <van-button
          :loading="loading"
          type="primary"
          class="btn act"
          @click="toSub()"
        >
        {{$t('m.qr')}}  </van-button
        >
      </div>  
      </div>
    </van-popup>
    <Bottom/>
    <Loading :show="isloading" v-if="isloading"  @updata="
        (val) => {
          isloading = false;
        }
      "/>
</div>
</template>
<script>
import Header from '../components/header.vue';
import Bottom from '../components/bottom.vue';
import Loading from '../components/loading.vue';
import {getUtoArc,getOrderList,toCompleteOrder,getProved,getTime2,getTime3,getTime4,getProveds,toApprove,toApproves,toRedeemOrder} from  "@/api/Public";
export default {
  name: "myContracts",
  components: {Header,Bottom,Loading},
  data() {
    return {
        current:0,
        info:'',
        xz:0,
        loading:false,
        isloading:false,
        show:false,
           slist:[
        {img:require('../assets/img/usdt.png'),
            name:"USDT",
            id:1,
            num:70},
            {img:require('../assets/img/slogo.png'),
            name:"ARC",
            id:2,
            num:10},
        ],
        sqNum:0,
        sqNums:0,
        list:[ ],
        userWallet:'',
        time2:"",
        time3:"",
        time4:""
    }
  },
  created(){
    this.userWallet = this.$store.getters.userWallet; 
    this.getTime()
    this.toGetList()
    
  },
  computed: {
    dqyy() {
      if (this.$store.getters.language) {
        return this.$store.getters.language;
      } else {
        return "en-US";
      }
    },  
  },
  methods:{
    getTime(){
       getTime2().then(res=>{
        console.log(res);
        this.time2=res*1000})
       getTime3().then(res=>{this.time3=res*1000})
       getTime4().then(res=>{this.time4=res*1000})
      
    },
    formatDate(e) {
      if (e == 0) {
        return "";
      } else {
        let date = new Date(e * 1000);
        let Y = date.getFullYear();
        let M = date.getMonth() + 1;
        let D = date.getDate();
        let h = date.getHours();
        let m = date.getMinutes();
        let s = date.getSeconds();
        if (M < 10) {
          M = "0" + M;
        }
        if (D < 10) {
          D = "0" + D;
        }
        if (h < 10) {
          h = "0" + h;
        }
        if (m < 10) {
          m = "0" + m;
        }
        if (s < 10) {
          s = "0" + s;
        }
        return `${Y}-${M}-${D} ${h}:${m}:${s}`;
      }
    },
      async toGetsq(){
      this.sqNum=await getProved(this.userWallet).then(res=>{ return res})
      this.sqNums=await getProveds(this.userWallet).then(res=>{ return res})
      console.log(this.sqNum,this.sqNums);
    },
    changer(e){
        this.current=e
        this.toGetList() 
    },
    toxz(v){
      this.xz=v
    },
    async toShow(e){
      this.info=e
      let num  =e.remainingAmount
      await this.toGetsq()
      getUtoArc(num).then(res=>{
          let arcAmount =(res*1).toFixed(4)
          this.$set(this.slist[0],'num',num)
          this.$set(this.slist[1],'num',arcAmount)
          this.show=true         
        })
    },
    toOutBack(e){
      this.info=e
      this.isloading=true
      toRedeemOrder(this.userWallet,this.info.orderIndex).then(res=>{
        this.isloading=false
        if(res){
          this.toGetList()
           this.$notify({
                message: this.$i18n.t("m.shcg"),
                type: "success",
              });
        }
      })
    },
    jssqNum(){
      if(this.xz.id==1){
       if(this.xz.num-this.sqNum>0){
        toApprove(this.userWallet,this.xz.num+1).then(res=>{
            console.log(res);
            if(res){   
              this.sqNum=this.xz.num
              this.scSelects()
            }else{
              this.lqloading=false
            }
        })
      }else{
        this.scSelects()
      }
      }
      if(this.xz.id==2){
       if(this.xz.num-this.sqNums>0){
        let num=Math.ceil(this.xz.num)
        toApproves(this.userWallet,num).then(res=>{
            console.log(res);
            if(res){   
              this.sqNum=this.xz.num 
              this.scSelects()
            }else{
              this.lqloading=false
            }
        })
      }else{
        this.scSelects()
      }
      }
    },
    toSub(){
      if(this.xz.id){
      this.show=false
      this.isloading=true
      this.jssqNum()
      }
    },
    scSelects(){
      toCompleteOrder(this.userWallet,this.info.orderIndex,this.xz.id).then(res=>{
       this.isloading=false
       if(res){  
         this.toGetList()        
            this.$notify({
                message: this.$i18n.t("m.zfcg"),
                type: "success",
              });
        }
      })
    },
    toGetList(){
        getOrderList(this.$store.getters.userWallet).then(res=>{
            console.log(res);
            if(res){
              this.list=[]
              res.map(el=>{
                let obj=Object.assign({},el)
                obj.amount= (el.amount/10**18)
                 obj.remainingAmount=(el.remainingAmount/10**18)
                 obj.completeAmount=(el.completeAmount/10**18)
                 obj.createdAts=el.createdAt*1000
                 let time=new Date().getTime()
                 
                 if(el.status==1){
                 let end= obj.createdAts+this.time2
                  obj.time=end-time
                  let end2= obj.createdAts+(this.time3+this.time2)
                  obj.time2=end2-time
                 }
                  if(el.status==2){
                 let end= obj.lastPaymentTime*1000+this.time4
                  obj.time3=end-time
                 }
                //  obj.time=time-obj.createdAts
                 this.list.unshift(obj)
              })
              // console.log(res);
              // this.list=res
            }
            
        })
    },
  }
}

</script>
<style lang="scss" scoped>
@import "../../src/assets/scss/_handle.scss";

$t-mf: "PingFang";
$t-f: "DINAlternate-Bold";

.home {
  background:  #000000 url("../assets/img/pbg.png")  no-repeat;
  // background: #161628;
  background-size:contain;
  background-position: center bottom;
  overflow-x: hidden;
  width: 750px;
  margin: 0 auto;
  min-height: 100vh;
    .content{
        margin-top: 188px;
        .title{
      font-size: 38px;
      font-weight: bold;
      color: #ffffff;
      margin: 72px auto;
      border-radius: 36px 0 36px 0;
      width: 360px;
      padding: 22px;
      position: relative;
    }
    .title::after{
      content: "";
      position: absolute;
      height: 4px;
      width: 100px;
      border-radius: 16px;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background: linear-gradient(200deg, #12c2fc 0%, #3ae2f8 31%, #14a5d0 49%, #441dda 79%, #0b1042 100%);
    }
        .tab{
            width: 100%;
            border-bottom:1px solid #2A2A2A ;
            margin-top: 40px;
                padding-bottom: 20px;
          
            .item{
                flex: 1;
                color: #ffffff;
                padding-bottom: 20px;
                font-size: 28px !important;
            }
            .act{
                border-bottom: 1px solid #35dcf7;
            }
        }
       .list{
        min-height: 50vh;
        .card{
           background: #00000070;
         background-size: cover;
    background-position: left top;
          align-items: center;
          border: 1px solid rgb(53, 53, 53);
      border-radius: 60px;
      // min-height: 200px;
      padding: 30px 40px;
      margin: 30px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
          .top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
              padding-bottom: 30px;
              border-bottom: 1px solid rgb(53, 53, 53);
            .time{
              font-size: 26px;
              color: #ABABAB;
              padding-right: 16px;
            }
            .status{
              border-radius: 56px;
              color:#ffffff;
              background: #2A2A2A;
              padding:10px 20px;
            }
          }
          .cont{
            width: 100%;
            .linex{
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              margin: 16px auto;
              .txt{
                font-size: 26px;
                color: #ffffff;
              }
              .num{
                        font-size: 38px;
        font-family: $t-f;
        font-weight: bold;
          background: linear-gradient(180deg, #00d5ff, #ffffff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    
        .dw{
          font-size: 22px;
          color: #12c2fc;
        }
              }
            }
            .box{
              display: flex;
              align-items: center;
              flex-direction: column;
              justify-content: center;
              border-radius: 16px;
              // background: #2a2a2a;
              background: transparent;
              border: 1px solid #28c0de;
              height: 120px;
              .stixl{
                font-size: 26px;
                color:#ABABAB;

              }
              .snum{
                 font-size: 32px;
                color:#ABABAB;
                margin-top: 16px;
              }
            }
          }

           .buttom{
        display: flex;
        width: 100%;    
    
        .btn{
         background: #2A2A2A;
          color: #ABABAB;
          border: none;
          border-radius: 100px;
          height: 68px;
          width: 100%;
          font-size: 24px;          
             margin: 30px auto;  
        }
        .act{
          color: #ffffff;
          background: linear-gradient(188.1deg, #178caa 18.52%, #28c0de 71.67%) }
      }
        }
       }
    }
   

   .popup{
        width: 672px;
        height: auto;
        background: #ffffff;
        padding: 30px 30px 40px;
        box-sizing: border-box;
        .top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 32px;
            .close{
                width:28px;

            }
        }
        .title{
            width: 100%;
            font-size:32px;
            font-weight: bold;
            color: #000000;
            text-align: left;
           
        }
        .tips{
          font-size: 28px;
          color: #2A2A2A;
          text-align: left;
        }
        .line{
            width: 100%;
            height: 1px;
            background: #e9e9e9;
            margin: 40px 0;
        }
        

        
        .list{
            .sitem{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 26px ;
                padding: 16px;
              border-radius: 10px;
              box-shadow: rgba(0, 0, 0, 0.075) 0px 6px 10px;
                .icon{
                    width:60px;
                    height:60px;
                    margin-right: 8px;
                    .ico{
                        width: 60px;
                        height: 60px;
                        border-radius: 100%;
                        box-shadow: rgba(0, 0, 0, 0.075) 0px 6px 10px;
                    }
                }
                .cont{
                    display: flex;
                    width: 120px;
                    flex-direction: column;
                    align-items: flex-start;
                    .name{
                        font-size: 28px;
                        font-weight: bold;
                    }
                }
                .num{
                    font-size: 28px;
                    color: #000000;
                    font-weight: bold;
                    flex: 1;                    
                    text-align: right;
                    overflow: hidden;
                }
            }
            .act{
              background: #676c6d;
              color: #ffffff;
              .cont{
                .name{
                  color: #ffffff;
                }
              }
              .num{
                color: #ffffff;
              }
            }
        }
        .buttom{
        display: flex;
        width: 100%;    
        margin: 60px auto 10px;  
        .btn{
            width: calc(100% - 200px);
      height: 80px;
      font-size: 32px;
      font-family: $t-mf;
      font-weight: 600;
      color: #ffffff;
      line-height: 80px;
      margin: 54px auto;
background: linear-gradient(90deg, #F83AFD 0%, #C333FF 31%, #A84BFF 49%, #724EFF 79%, #495AFA 100%);
box-shadow: 0px 4px 16px 4px rgba(207,182,247,1);
border-radius: 40px;
border: none;
  display: flex;
      align-items: center;
      justify-content: center;
        }
      }
    }
}

  </style>
